"use client";
import { CrossIcon } from "@/components/icons/CrossIcon";
import usePersistedStore from "@/lib/stateManagement/persistedState/persistedStore";
import getLink from "@/lib/storyblok/utils/getLink";
import { SnackbarStoryblok } from "@/lib/types/storyblok-blok-types";
import clsx from "clsx";
import Link from "next/link";
import React, { useCallback, useEffect } from "react";
import styles from "./Snackbar.module.css";

export const SnackbarBlok = ({ blok }: { blok: SnackbarStoryblok }) => {
  if (blok.link?.cached_url) {
    return (
      <Link className={styles.link} href={getLink(blok.link)}>
        <SnackbarInner blok={blok} />
      </Link>
    );
  }

  return <SnackbarInner blok={blok} />;
};

const SnackbarInner = ({ blok }: { blok: SnackbarStoryblok }) => {
  const [shouldShow, setShouldShow] = React.useState(false);
  const snackbar = usePersistedStore((state) => state.snackbars);
  const addSnackbar = usePersistedStore((state) => state.addSnackbar);

  const hasSeenSnackbar = snackbar.some(
    (snackbar) => snackbar.id === blok._uid
  );

  const handleSnackbarClose = useCallback(
    (e: React.MouseEvent<HTMLButtonElement>) => {
      e.preventDefault();
      e.stopPropagation();
      addSnackbar({
        id: blok._uid,
        text: blok.primaryText ?? "",
      });
      setShouldShow(false);
    },
    [addSnackbar, blok]
  );

  useEffect(() => {
    if (!hasSeenSnackbar) {
      setShouldShow(true);
    } else {
      setShouldShow(false);
    }
  }, [snackbar, blok]);

  if (!shouldShow) {
    return null;
  }

  return (
    <div
      style={{
        backgroundColor: blok.backgroundColor ?? "#000000",
      }}
      className={clsx(styles.wrapper)}
    >
      {blok.primaryText}
      <button className={styles.closeButton} onClick={handleSnackbarClose}>
        <CrossIcon />
      </button>
    </div>
  );
};
