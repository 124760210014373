import type { ReactNode } from "react";
import { Component } from "react";

type Props = {
  children?: ReactNode;
};

class ErrorBoundary extends Component<Props> {
  state: { error: null | Error };

  constructor(props: Props) {
    super(props);
    this.state = { error: null };
  }

  static getDerivedStateFromError(error: Error) {
    return { error: error };
  }

  render() {
    if (this.state.error) {
      return <p>{this.state.error.message}</p>;
    }
    return this.props.children;
  }
}

export default ErrorBoundary;
