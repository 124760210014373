"use client";

import { SnackbarStoryblok } from "@/lib/types/storyblok-blok-types";
import { usePathname } from "next/navigation";
import { useEffect } from "react";
import ErrorBoundary from "../ErrorBoundary/ErrorBoundary";
import styles from "./Snackbar.module.css";
import { SnackbarBlok } from "./SnackbarBlok";
import { useSnackbar } from "./SnackbarProvider";

type ClientSnackbarProps = {
  initialSnackbarBlok?: SnackbarStoryblok;
};

export function ClientSnackbar({ initialSnackbarBlok }: ClientSnackbarProps) {
  const { snackbarBlok, setSnackbarBlok, clearSnackbar } =
    useSnackbar(initialSnackbarBlok);
  const path = usePathname();

  useEffect(() => {
    if (initialSnackbarBlok) {
      setSnackbarBlok(initialSnackbarBlok);
    }

    return () => {
      clearSnackbar();
    };
  }, [initialSnackbarBlok, setSnackbarBlok, path]);

  return (
    <ErrorBoundary>
      <div className={styles.root}>
        {snackbarBlok && <SnackbarBlok blok={snackbarBlok} />}
      </div>
    </ErrorBoundary>
  );
}

export function SnackbarProxy({ initialSnackbarBlok }: ClientSnackbarProps) {
  const { setSnackbarBlok, clearSnackbar } = useSnackbar();
  const path = usePathname();

  useEffect(() => {
    if (initialSnackbarBlok) {
      setSnackbarBlok(initialSnackbarBlok, true);
    }

    return () => {
      clearSnackbar();
    };
  }, [initialSnackbarBlok, setSnackbarBlok, path]);

  return null;
}
