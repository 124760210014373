"use client";
import useDevice from "@/hooks/useDevice";
import useHideOnScroll from "@/hooks/useHideOnScroll";
import clsx from "clsx";
import { useEffect, useRef, useState } from "react";
import styles from "./index.module.css";

type Props = {
  children: any;
  isCampaign?: boolean;
  home: boolean;
};

const NavbarWrap = ({ children, home }: Props) => {
  const headerHeight = 300;
  const [scrollPosition, setScrollPosition] = useState(0);
  const navbarRef = useRef(null);
  let { isHidden, isTop } = useHideOnScroll(home);
  const device = useDevice();
  let offsetHeight = (headerHeight - scrollPosition) / headerHeight;
  if (offsetHeight < 0) offsetHeight = 0;
  useEffect(() => {
    let ticking = false;

    const handleScroll = () => {
      if (!ticking) {
        window.requestAnimationFrame(() => {
          const newScrollPosition = window.scrollY;
          setScrollPosition(newScrollPosition);
          ticking = false;
        });

        ticking = true;
      }
    };
    handleScroll();
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  return (
    <>
      <div
        id="navbarWrapper"
        className={clsx(
          styles.navWrapper,
          home && styles.home,
          isHidden && styles.hide,
          isTop && home && !device.mobile && styles.isTop,
        )}
        ref={navbarRef}
      >
        <style>{`:root{--logo-offset-height: ${offsetHeight}`}</style>
        {children}
      </div>
      {home && <div className={styles.spacingHome} />}
    </>
  );
};
export default NavbarWrap;
