"use client";
import { SnackbarStoryblok } from "@/lib/types/storyblok-blok-types";
import { createContext, ReactNode, useContext, useState } from "react";

interface SnackbarProviderProps {
  children: ReactNode;
}

const SnackbarContext = createContext<SnackbarContextType>(
  {} as SnackbarContextType
);

interface SnackbarContextType {
  snackbarBlok?: SnackbarStoryblok;
  setSnackbarBlok: (blok: SnackbarStoryblok, override?: boolean) => void;
  clearSnackbar: () => void;
}

export const SnackbarProvider = ({ children }: SnackbarProviderProps) => {
  const [snackbarBlok, setSnackbarBlokState] = useState<
    SnackbarStoryblok | undefined
  >();

  const setSnackbarBlok = (
    blok: SnackbarStoryblok,
    override: boolean = false
  ) => {
    setSnackbarBlokState((current) => {
      if (!current || override) {
        return blok;
      }
      return current;
    });
  };

  const clearSnackbar = () => {
    setSnackbarBlokState(undefined);
  };

  return (
    <SnackbarContext.Provider
      value={{ snackbarBlok, setSnackbarBlok, clearSnackbar }}
    >
      {children}
    </SnackbarContext.Provider>
  );
};

export const useSnackbar = (initialBlok?: SnackbarStoryblok) => {
  const context = useContext(SnackbarContext);
  if (!context) {
    throw new Error("useSnackbar must be used within SnackbarProvider");
  }

  const { snackbarBlok, setSnackbarBlok, clearSnackbar } = context;

  return {
    snackbarBlok: snackbarBlok ?? initialBlok,
    setSnackbarBlok,
    clearSnackbar,
  };
};
